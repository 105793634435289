header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* ------CALL TO ACTION BUTTONS------- */

.cta{
    margin-top: 1.3rem;
    margin-top: 1.3rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* Header Socials */

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 13rem;
    bottom: 13rem;

}

.header__socials::after{
    content:"" ;
    width: 1px;
    height: 2rem;
    background: var(--color-primary);

}

/* ---------------ME Image------------------ */

.me{
    /* background:linear-gradient(var(--color-primary), transparent); */ 
    width: 23rem;
    width: 23rem;
    height: 30rem;
    position: absolute;
    left:calc(50% - 11rem);
    margin-bottom: 10rem;
    margin-bottom: 10rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 4.5rem 1rem 1.5rem;
    padding: 5rem 4.5rem 1rem 1.5rem;
    
}

/* ---------------Scroll Down-------------------- */

.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ----------------Media Queries (Tablets) Medium Devices------------------- */
@media screen and(max-width:1024px){
    .header{
        height: 68vh;
    }

}


/* ----------------Media Queries (Mobile Phones) Small Devices------------------- */
@media screen and (max-width:600px) {
    
    .me{
        width: 22rem;
        height: 30rem;
        left:calc(50% - 11rem);
        margin-bottom: 1000rem;
        border-radius: 12rem 12rem 0 0;
        padding: 5rem 1.5rem 1.5rem 1.5rem;
        
    }


    .header__socials,
    .scroll__down{
        display: none;
    }
}

